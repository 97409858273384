<template>
  <div class="mt-6 mx-3 mx-md-6">
    <v-card class="pa-3 pa-md-6 card">
      <v-form ref="form" v-model="valid">
        <v-card-text class="pa-0">
          <v-row>
            <!-- <v-col cols="12">
              <div
                class="dropZone d-flex align-center"
                @dragenter="dragging = true"
                @dragleave="dragging = false"
              >
                <v-avatar v-if="file" size="96px" class="mx-6">
                  <v-img :src="imageBase64"></v-img>
                </v-avatar>
                <div
                  v-else
                  class="mx-6 rounded-div d-flex align-center justify-center"
                >
                  <img alt="Avatar" src="../../assets/default-res-icon.svg" />
                </div>
                <v-btn :disabled="selected" @click="onPickFile" class="primary mr-2"
                  >Select Image</v-btn
                >
                <v-btn v-if="file" @click="removeFile" icon>
                  <v-icon class="primary--text">mdi-close</v-icon>
                </v-btn>
                <span
                  v-if="!$vuetify.breakpoint.xs"
                  @drag="onChange"
                  class="text-body-1 ml-6 d-flex align-center"
                >
                  <span class="mr-2 text-button">Or</span>
                  <v-icon class="mx-2">mdi-cloud-upload-outline</v-icon>
                  <span class="text-button">DRAG and DROP an IMAGE</span>
                </span>
                <input
                  v-if="!file"
                  type="file"
                  ref="fileInput"
                  @change="onChange"
                  accept=".jpg,.jpeg,.png"
                />
              </div>
            </v-col> -->
            <v-col cols="12">
              <v-text-field
                v-model="user.name"
                :rules="nameRules"
                label="Name"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                disabled
                label="E-mail"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-0">
          <v-spacer></v-spacer>
          <v-btn @click="cancel" outlined class="primary--text">Cancel</v-btn>
          <v-btn :disabled="!valid || !changeValue || loading" :loading="loading" @click="updateProfile" class="primary">Save Changes</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import store from '../../store'
import {eventBus} from '../../main'
export default {
  props: ['userInfo'],
  data() {
    return {
      valid: true,
      user: {
        name: "",
        email: "",
      },

      nameRules: [
        (v) => !!v || "Name is required",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      dragging: false,
      file: "",
      imageBase64: "",
      imageData: "",
      selected: false,

      change: null,
      changeValue: false,
      currentUser: {},

      loading: false,
    };
  },
  watch: {
    user: {
      handler(val) {
        // console.log('changing')
        this.change+=1
        if(this.change==1) {
          this.currentUser = Object.assign({}, val)
          // console.log('change 1')
        } else {
          this.changeValue = JSON.stringify(this.currentUser) !== JSON.stringify(val)
          // console.log('change > 1', this.currentUser, val)
        }
      },
      deep: true
    }
  },
  created() {
    // console.log(this.userInfo)
    this.setUserToForm()
    // console.log(this.valid)
    // this.valid = true
  },
  methods: {
    cancel() {
      this.setUserToForm()
      this.$emit('cancel')
    },
    setUserToForm() {
      this.user.name = this.userInfo.name
      this.user.email = this.userInfo.email
    },
    updateProfile() {
      this.loading = true
      // console.log(this.user.name, this.email)
      store.dispatch('updateProfile', this.user)
      .then(res => {
        if (res.status > 205) {
          this.loading = false
          const snackbar = {
            success: false,
            snackbarColor: "#FF5252",
            snackbarText: res.data.message
          }
          this.$emit('updated', snackbar)
        } else {
          this.loading = false
          const snackbar = {
            success: true,
            snackbarColor: "#31A274",
            snackbarText: `${res.data.message}`
          }
          eventBus.$emit('userUpdated')
          this.$emit('updated', snackbar)
        }
      })
    },

    // for image upload
    onChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        this.dragging = false
        return
      }
      // console.log(e.target.files)
      this.createFile(files[0]) // for multiple, pass files
    },
    createFile(file) {
      const formData = new FormData()
      formData.append("image", file)
      this.imageData = formData
      // console.log(...this.imageData)

      this.file = file
      this.dragging = false
      this.selected = true
      this.previewImage(file)
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.imageBase64 = e.target.result
        // console.log(this.imageBase64)
      }
      reader.readAsDataURL(file)
    },
    onPickFile() {
      this.$refs.fileInput.click()
    },
    removeFile() {
      this.imageBase64 = ""
      this.file = ""
      this.dragging = false
      this.selected = false
    },
  },
};
</script>

<style scoped>
.dropZone {
  height: 150px;
  border: 2px dashed #a1a1a1;
}
.dropZone input {
  position: absolute;
  /* cursor: pointer; */
  top: 0px;
  right: 0;
  bottom: 0;
  left: 320px;
  width: 100%;
  height: 150px;
  opacity: 0;
}
.rounded-div {
  height: 96px;
  width: 96px;
  border: 1px solid #6e6e6e;
  border-radius: 50%;
}
</style>
